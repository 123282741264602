import React, { Component, useEffect } from 'react'
import * as Product from 'modules/product/components/Reference'
import * as Category from 'modules/category/components/Reference'
import * as Renderer from 'components/shared/Renderer/Content'
import * as Width from '../../modules/page/width/components/Reference'

/**
 *
 */
export const ProductList = ({ className }) => {
  const { category } = React.useContext(Category.Context)
  const { product, length } = React.useContext(Product.Context)
  const { width } = React.useContext(Width.Context)
  const WIDTH_THRESHOLD = 780
  const responsiveHeight = 700 * length
  const laptopHeight = 290 * length
  return (
    <Renderer.WidthIframeRender
      src={`/iframe/product-lists/${category}/${product.wordpress_id}`}
      laptopHeight={laptopHeight}
      responsiveHeight={responsiveHeight}
      className={className}
      threshold={WIDTH_THRESHOLD}
      width={width}
    />
  )
}

/**
 *
 */
export const AllProductList = ({ className }) => {
  const { category } = React.useContext(Category.Context)
  const { width } = React.useContext(Width.Context)
  const WIDTH_THRESHOLD = 780
  const responsiveHeight = 700 * category.length
  const laptopHeight = 290 * category.length
  return (
    <Renderer.WidthIframeRender
      src={`/iframe/all-product-lists/${category.companyNode.companySlug}`}
      laptopHeight={laptopHeight}
      responsiveHeight={responsiveHeight}
      className={className}
      threshold={WIDTH_THRESHOLD}
      width={width}
    />
  )
}

export const archLogProducts = ({ className }) => {
  const { product } = React.useContext(Product.Context)
  if (product.acf.relatedArchProductId) {
    return (
      <Renderer.DefaultIframeRenderer
        src={`/iframe/arch-log-products/${product.wordpress_id}`}
        height={340}
        className={className}
      />
    )
  }
  return <></>
}
