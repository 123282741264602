import React from 'react'
import { Content as HeaderContent } from './Header'
import { Content as BodyContent } from './Body'
import { Content as FooterContent } from 'components/shared/Common_button'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Header = () => (
  <div>
    <HeaderContent />
  </div>
)

/**
 *
 */
const Body = () => (
  <div>
    <BodyContent />
  </div>
)

/**
 *
 */
const Footer = () => (
  <div>
    <FooterContent />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
    <Body />
    <Footer />
  </Container>
)
