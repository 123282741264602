import React from 'react'
import { graphql } from 'gatsby'
import { Content } from 'partials/ProductsPage'
import * as Products from 'modules/product/components/Reference'
import { Layout as PostPageLayout } from 'layouts/PostPageLayout'
import * as Categories from '../modules/category/components/Reference'
import * as Company from '../modules/company/components/Reference'

/**
 *商品記事のデータと企業記事のデータの重複しているデータをフィルタリングしてカテゴリのプロバイダーに渡す
 */
const ProductTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost
  const postLength = data.postLength.nodes.length - 1
  const { styleModel } = pageContext
  const { companySlug } = pageContext

  return (
    <PostPageLayout styleModel={styleModel} meta={pageContext.meta}>
      <Products.Provider product={post} length={postLength}>
        <Categories.Provider category={companySlug}>
          <Company.Provider company={data.company}>
            <Content />
          </Company.Provider>
        </Categories.Provider>
      </Products.Provider>
    </PostPageLayout>
  )
}

/**
 *
 */
export const query = graphql`
  query ProductTemplateQuery(
    $companySlug: String!
    $id: Int!
    $postStatus: String!
  ) {
    wordpressPost(
      categories: { elemMatch: { slug: { ne: "companies", eq: $companySlug } } }
      wordpress_id: { eq: $id }
    ) {
      acf {
        relatedArchProductId
        archLogSearchResultUrl
      }
      slug
      id
      content
      title
      excerpt
      wordpress_id
      featured_media {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
    }
    allWordpressCategory(
      filter: { parent_element: { slug: { eq: "companies" } } }
    ) {
      nodes {
        name
        slug
      }
    }
    company: wordpressPost(
      categories: { elemMatch: { slug: { eq: $companySlug, in: "companies" } } }
    ) {
      acf {
        mailAddress
      }
      title
    }
    postLength: allWordpressPost(
      filter: {
        categories: {
          elemMatch: { slug: { eq: $companySlug, ne: "companies" } }
        }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        title
      }
    }
  }
`

/**
 *
 */
export default ProductTemplate
