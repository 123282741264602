import React from 'react'
import * as Iframe from 'components/shared/Iframe'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Iframe.archLogProducts />
  </Container>
)
