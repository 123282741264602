import React from 'react'
import * as styles from './styles.module.scss'
import * as Product from 'modules/product/components/Reference/Properties'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Product.Title className={styles.Title} />
  </Container>
)
