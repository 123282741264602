import React from 'react'

/**
 */
export const useElementPresence = (id) => {
  const [isElementPresent, setIsElementPresent] = React.useState(false)

  React.useEffect(() => {
    const element = document.getElementById(id)
    setIsElementPresent(!!element)
  }, [id])

  return isElementPresent
}
