import React from 'react'
import * as Iframe from 'components/shared/Iframe'
import * as Width from '../../../../modules/page/width/components/Reference'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <>{children}</>

/**
 */
/**
 */
export const Title = () => {
  return <div className={styles.Title}>関連記事</div>
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Width.Initializer>
      <Title />
      <Iframe.ProductList />
    </Width.Initializer>
  </Container>
)
