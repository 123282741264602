import React, { useRef, useLayoutEffect } from 'react'
import * as Product from 'modules/product/components/Reference/Properties'
import { Content as ProductRelatedPostContent } from './ProductRelatedPost'
import { Content as AddProductListContent } from './AddProductList'
import * as styles from './styles.module.scss'
import * as Link from 'components/shared/Links'
import { PostOwnedByCompanyInitializer } from 'modules/page/design/components/Reference/PostOwnedByCompanyInitializer'
import { ActionButtons } from 'components/shared/ActionButton'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const ProductPost = () => (
  <div className={styles.Container_ProductPost}>
    <Product.Content />
  </div>
)

/**
 *
 */
const ProductRelatedPost = () => (
  <>
    <ProductRelatedPostContent />
  </>
)

/**
 *
 */
const AddProductList = () => (
  <>
    <AddProductListContent />
  </>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <ProductPost />
    <ActionButtons />
    <AddProductList />
    <ProductRelatedPost />
  </Container>
)
