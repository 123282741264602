import React from 'react'
import { Content as TitleContent } from './Title'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Title = () => <TitleContent />

/**
 *
 */
export const Content = () => (
  <Container>
    <Title />
  </Container>
)
