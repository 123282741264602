import React from 'react'
import * as Link from 'components/shared/Links'
import * as styles from './styles.module.scss'
import { PostOwnedByCompanyInitializer } from 'modules/page/design/components/Reference/PostOwnedByCompanyInitializer'
import { useElementPresence } from 'utils/useElementPrecense'

/**
 * 資料請求、プロジェクト追加、お問い合わせボタン
 */
export const ActionButtons = () => {
  const isFormPresent = useElementPresence('form')
  const { visible } = Link.useVisibleArchLogSearchResultLink()

  return (
    <PostOwnedByCompanyInitializer>
      <div className={styles.Container_Button}>
        {isFormPresent ? (
          <div className={styles.Container_Button_Content}>
            <Link.DocumentRequestButton
              className={styles.Container_Button_Content_BlueButton}
            />
          </div>
        ) : null}
        <div className={styles.Container_Button_Content}>
          <Link.InformationRequestButton
            className={styles.Container_Button_Content_BlueButton}
          />
        </div>
        {visible ? (
          <div className={styles.Container_Button_Content}>
            <Link.ArchLogSearchLinkWithPrSite
              className={styles.Container_Button_Content_RedButton}
            />
          </div>
        ) : null}
      </div>
    </PostOwnedByCompanyInitializer>
  )
}
